import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout/Layout"
import Head from "../components/Layout/Head"
import CS01Intro from "../components/CaseStudy/CS01Intro"
import CS02Video from "../components/CaseStudy/CS02Video"
import CS03Process from "../components/CaseStudy/CS03Process"
import CS04Gallery from "../components/CaseStudy/CS04Gallery"
import CS05Related from "../components/CaseStudy/CS05Related"

const CaseStudyTemplate = ({ data }) => {
  const {
    title,
    services,
    clientQuoteBy,
    clientQuoteByTitle,
  } = data.contentfulCaseStudyEntry
  const { introduction } = data.contentfulCaseStudyEntry.introduction
  const { title: client } = data.contentfulCaseStudyEntry.client
  const { clientQuote } = data.contentfulCaseStudyEntry.clientQuote
  const { file } = data.contentfulCaseStudyEntry.featuredVideo
  const {
    process01BuildUnderstanding: understanding,
  } = data.contentfulCaseStudyEntry.process01BuildUnderstanding
  const {
    process02BuildSolutions: solutions,
  } = data.contentfulCaseStudyEntry.process02BuildSolutions
  const {
    process03BuildMomentum: momentum,
  } = data.contentfulCaseStudyEntry.process03BuildMomentum
  const { imageGallery } = data.contentfulCaseStudyEntry

  return (
    <Layout>
      <Head title={title} />
      <CS01Intro
        title={title}
        introduction={introduction}
        client={client}
        services={services}
        clientQuote={clientQuote}
        clientQuoteBy={clientQuoteBy}
        clientQuoteByTitle={clientQuoteByTitle}
      />
      <CS02Video data={file} />
      <CS03Process
        understanding={understanding}
        solutions={solutions}
        momentum={momentum}
      />
      <CS04Gallery data={imageGallery} />
      <CS05Related data={data.allContentfulCaseStudyEntry} />
    </Layout>
  )
}

export default CaseStudyTemplate

export const pageQuery = graphql`
  query($slug: String!) {
    allContentfulCaseStudyEntry(filter: { slug: { ne: $slug } }) {
      edges {
        node {
          id
          title
          slug
          featuredTagline
          featuredImage {
            fluid(quality: 100) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
    contentfulCaseStudyEntry(slug: { eq: $slug }) {
      title
      introduction {
        introduction
      }
      client {
        title
      }
      services {
        title
      }
      clientQuote {
        clientQuote
      }
      clientQuoteBy
      clientQuoteByTitle
      featuredVideo {
        file {
          url
        }
      }
      process01BuildUnderstanding {
        process01BuildUnderstanding
      }
      process02BuildSolutions {
        process02BuildSolutions
      }
      process03BuildMomentum {
        process03BuildMomentum
      }
      imageGallery {
        fluid(quality: 100) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
  }
`
