import React, { useState, useEffect } from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import Grid from "../Layout/Grid"
import CarouselLeft from "../../images/icons/CarouselLeft.svg"
import CarouselRight from "../../images/icons/CarouselRight.svg"

const GalleryWrapper = styled.section`
  margin: 0 0 3rem;

  @media screen and (min-width: 800px) {
    padding: 1rem;
  }

  .slideshow {
    display: flex;
    align-items: center;

    .previous-slide,
    .next-slide {
      cursor: pointer;
      width: 2rem;

      @media screen and (min-width: 800px) {
        padding: 1rem;
        width: 4rem;
      }
    }

    .gallery {
      display: flex;
      overflow-x: scroll;
      overflow-y: hidden;
      width: 100%;

      .slide {
        padding: 0 1rem;
        width: 60vw;
        flex-shrink: 0;
      }

      .slide:nth-child(1) {
        padding: 0 1rem 0 0;
      }

      .slide:last-child {
        padding: 0 0 0 1rem;
      }
    }
  }
`

const CS04Gallery = ({ data }) => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const [totalSlides, setTotalSlides] = useState(0)

  useEffect(() => {
    setTotalSlides(data.length)
  }, [data.length])

  const PreviousSlide = () => {
    if (currentSlide > 0) {
      setCurrentSlide(currentSlide - 1)
    }
    const element = document.getElementById(`slide-${currentSlide}`)
    element.scrollIntoView()
  }

  const NextSlide = () => {
    if (currentSlide < totalSlides - 1) {
      setCurrentSlide(currentSlide + 1)
    }

    const element = document.getElementById(`slide-${currentSlide}`)
    element.scrollIntoView()
  }

  return (
    <GalleryWrapper>
      <Grid>
        <div className="slideshow">
          <div className="previous-slide">
            <CarouselLeft className="arrow" onClick={PreviousSlide} />
          </div>
          <div className="gallery">
            {data.map((image, index) => {
              return (
                <div key={index} id={`slide-${index}`} className="slide">
                  <Img
                    fluid={image.fluid}
                    style={{
                      height: "100%",
                    }}
                  />
                </div>
              )
            })}
          </div>
          <div className="next-slide">
            <CarouselRight className="arrow" onClick={NextSlide} />
          </div>
        </div>
      </Grid>
    </GalleryWrapper>
  )
}

export default CS04Gallery
