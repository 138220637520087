import React from "react"
import styled from "styled-components"
import Grid from "../Layout/Grid"

const VideoWrapper = styled.section`
  margin: 0 0 3rem;
  padding: 1rem;

  @media screen and (min-width: 800px) {
    padding: 1rem 6rem;
  }
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const CS02Video = data => {
  return (
    <VideoWrapper>
      <Grid>
        <div className="wrapper">
          <video
            width="100%"
            height="100%"
            src={`https:${data.data.url}`}
            type="video/mp4"
            controls
          />
        </div>
      </Grid>
    </VideoWrapper>
  )
}

export default CS02Video
