import React from "react"
import styled from "styled-components"
import Grid from "../Layout/Grid"
import MoreWork from "../Layout/MoreWork"

const RelatedWrapper = styled.section`
  padding: 0;

  @media screen and (min-width: 800px) {
    padding: 0 6rem;
  }
  .related-title {
    text-transform: lowercase;
  }

  .related-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1rem;

    @media screen and (min-width: 800px) {
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 2rem;
    }

    padding: 1rem 0 3rem;
  }
`

const CS05Related = data => {
  let relatedPosts = Object.keys(data).map(key => data[key])

  return (
    <RelatedWrapper>
      <Grid>
        <h2 className="related-title">More projects</h2>
        <div className="related-wrapper">
          {relatedPosts[0].edges.map(edge => {
            const {
              id,
              title,
              slug,
              featuredTagline,
              featuredImage,
            } = edge.node

            return (
              <MoreWork
                key={id}
                title={title}
                url={slug}
                tagline={featuredTagline}
                image={featuredImage.fluid}
              />
            )
          })}
        </div>
      </Grid>
    </RelatedWrapper>
  )
}

export default CS05Related
